var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "workflow-audit" },
    [
      !_vm.taskViewMode
        ? _c(
            "van-form",
            [
              _c("div", { staticClass: "area-title" }, [
                _c("p", { staticClass: "title" }, [_vm._v(" 审核 ")])
              ]),
              _c(
                "van-radio-group",
                {
                  staticClass: "audit-type",
                  attrs: { direction: "horizontal" },
                  model: {
                    value: _vm.auditType,
                    callback: function($$v) {
                      _vm.auditType = $$v
                    },
                    expression: "auditType"
                  }
                },
                _vm._l(
                  _vm._.get(_vm.taskDetail, "paramMap.FUNCTION", []),
                  function(item) {
                    return _c(
                      "van-radio",
                      { key: item, attrs: { name: item } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._.get(
                                _vm._.find(_vm.dictList, {
                                  type: "FUNCTION",
                                  code: item
                                }),
                                "label",
                                "-"
                              )
                            ) +
                            " "
                        )
                      ]
                    )
                  }
                ),
                1
              ),
              _c("van-field", {
                attrs: {
                  rows: "1",
                  autosize: "",
                  label: "审核意见",
                  type: "textarea",
                  placeholder: "请输入审核意见"
                },
                model: {
                  value: _vm.auditForm.comment,
                  callback: function($$v) {
                    _vm.$set(_vm.auditForm, "comment", $$v)
                  },
                  expression: "auditForm.comment"
                }
              }),
              _c(
                "div",
                { staticStyle: { margin: "16px", "text-align": "center" } },
                [
                  _c(
                    "van-button",
                    {
                      attrs: {
                        size: "normal",
                        round: "",
                        type: "info",
                        "native-type": "submit"
                      },
                      on: {
                        click: function($event) {
                          return _vm.confirmAudit()
                        }
                      }
                    },
                    [_vm._v(" 提交审核 ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("van-divider"),
      _vm._l(_vm.taskDetail.processDetailInfoVoList, function(item) {
        return _c(
          "div",
          { key: item.id, staticClass: "audit-item" },
          [
            _c("van-cell", {
              attrs: {
                title: "审核时间",
                value: _vm.utils.isEffectiveCommon(item.auditTime)
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "审核角色",
                value: _vm.utils.isEffectiveCommon(item.currentRole)
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "审核用户",
                value: _vm.utils.isEffectiveCommon(item.handlerName)
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "审核意见",
                value: _vm.utils.isEffectiveCommon(item.opinion)
              }
            }),
            _c("van-divider")
          ],
          1
        )
      }),
      _c(
        "van-popup",
        {
          attrs: { round: "", position: "bottom" },
          model: {
            value: _vm.dialog.userList,
            callback: function($$v) {
              _vm.$set(_vm.dialog, "userList", $$v)
            },
            expression: "dialog.userList"
          }
        },
        [
          _c("van-picker", {
            attrs: {
              "show-toolbar": "",
              "value-key": "realName",
              title: _vm.utils.isEffectiveCommon(_vm.taskDetail.nextRoleName),
              loading: _vm.loading,
              columns: _vm.userList.list
            },
            on: {
              cancel: function($event) {
                _vm.dialog.userList = false
              },
              confirm: _vm.confirmSelectUser
            }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }