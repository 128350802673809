<template>
  <div class="workflow-audit">
    <van-form v-if="!taskViewMode">
      <div class="area-title">
        <p class="title">
          审核
        </p>
      </div>
      <van-radio-group v-model="auditType" direction="horizontal" class="audit-type">
        <van-radio v-for="item in _.get(taskDetail, 'paramMap.FUNCTION', [])" :key="item" :name="item">
          {{ _.get(_.find(dictList, { type: 'FUNCTION', code: item }), 'label', '-') }}
        </van-radio>
      </van-radio-group>
      <van-field
        v-model="auditForm.comment"
        rows="1"
        autosize
        label="审核意见"
        type="textarea"
        placeholder="请输入审核意见"
      />
      <div style="margin: 16px;text-align:center;">
        <van-button size="normal" round type="info" native-type="submit" @click="confirmAudit()">
          提交审核
        </van-button>
      </div>
    </van-form>
    <van-divider />
    <div v-for="item in taskDetail.processDetailInfoVoList" :key="item.id" class="audit-item">
      <van-cell title="审核时间" :value="utils.isEffectiveCommon(item.auditTime)" />
      <van-cell title="审核角色" :value="utils.isEffectiveCommon(item.currentRole)" />
      <van-cell title="审核用户" :value="utils.isEffectiveCommon(item.handlerName)" />
      <van-cell title="审核意见" :value="utils.isEffectiveCommon(item.opinion)" />
      <van-divider />
    </div>
    <van-popup v-model="dialog.userList" round position="bottom">
      <van-picker
        show-toolbar
        value-key="realName"
        :title="utils.isEffectiveCommon(taskDetail.nextRoleName)"
        :loading="loading"
        :columns="userList.list"
        @cancel="dialog.userList = false"
        @confirm="confirmSelectUser"
      />
    </van-popup>
  </div>
</template>

<script>
import { List, Divider, RadioGroup, Radio, Form, Toast, Field, Popup, Picker, Button, Cell } from 'vant'
export default {
  name: 'WorkflowAudit',
  components: {
    [List.name]: List,
    [Divider.name]: Divider,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Form.name]: Form,
    [Toast.name]: Toast,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Button.name]: Button,
    [Cell.name]: Cell
  },
  props: {
    // 展示模式
    viewMode: {
      type: Boolean,
      default: false
    },
    // 隐藏审核记录
    hideResultList: {
      type: Boolean,
      default: false
    },
    pageKey: {
      type: String,
      default: ''
    },
    businessKey: {
      type: [String, Number],
      default: ''
    },
    taskId: {
      type: String,
      default: ''
    },
    procInstId: {
      type: String,
      default: ''
    },
    // 附加参数
    variable: {
      type: Object,
      default () {
        return {}
      }
    },
    // 页面跳转用url
    viewUrl: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      selectUser: '',
      auditForm: {
        comment: ''
      },
      auditType: '',
      taskDetail: {
        nextRole: '',
        paramMap: {
          FUNCTION: ['SUBMIT']
        },
        processDetailInfoVoList: []
      },
      dictList: [],
      userList: {
        list: []
      },
      auditResultList: {
        list: []
      },
      dialog: {
        userList: false
      },
      loading: false
    }
  },
  computed: {
    taskViewMode () {
      if (this.viewMode) {
        return this.viewMode
      } else return !!(this.$route.query.taskViewMode && Number(this.$route.query.taskViewMode) === 1)
    }
  },
  watch: {
    taskId: {
      handler: 'init',
      immediate: true
    },
    procInstId: {
      handler: 'init',
      immediate: true
    }
  },
  methods: {
    init () {
      if (this.taskId || this.procInstId) {
        this.getTaskDetail()
      } else {
        this.getWorkflowDict()
      }
    },
    getWorkflowDict () {
      return new Promise((resolve, reject) => {
        this.api.system.workflow.dictList().then(result => {
          this.dictList = result.data.value
          resolve(result.data.value)
        }).catch(e => reject(e))
      })
    },
    initWorkflow () {
      return new Promise((resolve, reject) => {
        this.api.audit.workflowInit({ pageKey: this.pageKey, businessKey: this.businessKey }).then(res => {
          resolve(res.data.value)
        }).catch(e => reject(e))
      })
    },
    createWorkflow () {
      return new Promise((resolve, reject) => {
        this.api.audit.workflowCreate({ pageKey: this.pageKey, businessKey: this.businessKey, userId: this.selectUser, variable: { viewUrl: this.viewUrl || this.$route.fullPath, ...this.variable } }).then(res => {
          this.taskDetail.taskId = res.data.value.taskId
          resolve(res.data.value)
        }).catch(e => reject(e))
      })
    },
    getTaskDetail () {
      let api = this.api.audit.taskDetail
      if (this.procInstId) {
        api = this.api.audit.taskDetailHistory
      }
      Promise.all([
        this.getWorkflowDict(),
        api((!this.taskViewMode && this.taskId) || this.procInstId)
      ]).then(result => {
        if (!this.taskViewMode && this.taskId) {
          if (result[1].data.value.length !== 0) {
            this.taskDetail = result[1].data.value
          }
        } else {
          this.taskDetail.processDetailInfoVoList = result[1].data.value
        }
      }).finally(() => {
      })
    },
    confirmAudit () {
      // this.auditType = type
      if (!this.auditType) {
        Toast.fail('请选择审核结果')
        return false
      }
      if (this.taskDetail.taskId) {
        if (this.taskDetail.nextRole && !['REVOKE', 'TERMINATION'].includes(this.auditType)) {
          this.getUserList({ pageNum: 1, pageSize: 100 }).then(() => {
            this.dialog.userList = true
          }).finally(() => {
          })
        } else {
          this.loading = true
          Toast.loading({ duration: 0, forbidClick: true })
          this.submitAudit().finally(() => {
            Toast.clear()
            this.loading = false
          })
        }
      } else {
        // 没有taskId，需要进行初始化
        this.initWorkflow().then(res => {
          return new Promise(resolve => {
            if (res.nextRoleCode && !['REVOKE', 'TERMINATION'].includes(this.auditType)) {
              this.taskDetail.nextRole = res.nextRoleCode
              this.taskDetail.nextRoleName = res.nextRoleName
              this.getUserList({ pageNum: 1, pageSize: 100 }).then(() => {
                this.dialog.userList = true
                resolve()
              })
            } else {
              this.submitAudit().finally(() => {
                resolve()
              })
            }
          })
        }).finally(() => {
        })
      }
    },
    getUserList ({ pageNum, pageSize }) {
      return new Promise((resolve, reject) => {
        this.api.audit.userList({ roleId: this.taskDetail.nextRole, pageNum, pageSize }).then(res => {
          this.userList = res.data.value
          if (this.userList) {
            this.userList.list.unshift({
              userId: '',
              realName: ''
            })
          }
          resolve(res.data.value)
        }).catch(e => {
          reject(e)
        }).finally(() => {
        })
      })
    },
    confirmSelectUser (value) {
      if (!value.userId) {
        Toast.fail('请选择审核人')
        return
      }
      this.loading = true
      Toast.loading({ duration: 0, forbidClick: true })
      this.selectUser = value.userId
      if (this.taskDetail.taskId) {
        this.submitAudit().then(() => {
          this.dialog.userList = false
          Toast.clear()
          this.loading = false
        }).finally(() => {
          this.loading = false
        })
      } else {
        this.createWorkflow().then(() => {
          return this.submitAudit()
        }).then(() => {
          this.dialog.userList = false
          Toast.clear()
          this.loading = false
        }).finally(() => {
          this.loading = false
        })
      }
    },
    submitAudit () {
      return new Promise((resolve, reject) => {
        const formData = {
          comment: this.auditForm.comment,
          nextRoleCode: this.taskDetail.nextRole,
          nextUserId: this.selectUser,
          taskId: this.taskDetail.taskId,
          variable: { viewUrl: this.viewUrl || this.$route.fullPath, ...this.variable }
        }
        this.api.audit[this._.toLower(this.auditType)](formData).then(result => {
          Toast.clear()
          Toast.success(result.data.message || '操作成功')
          this.$emit('confirmAudit', formData, result.data.value)
          resolve(result.data.value)
        }).catch(e => {
          reject(e)
        })
      })
    },
    close () {
      this.selectUser = ''
    }
  }
}
</script>

<style lang="less" scoped>
.workflow-audit {
  .audit-type {
    margin:15px;
    font-size: 14px;
  }
  button {
    height: 40px;
    width: 100px;
  }
  .audit-item {
    font-size: 14px;
    margin-top: 20px;
    .van-cell__title{
      flex:0.3;
    }
    .van-cell__value{
      flex: 0.7;
      text-align: left;
    }
  }
}
</style>
